import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { ContentCategoriesRendering, Item } from '@sitecore/types/manual/ContentCategories';
import { Bleed, Box, Divider, Expandable, Grid, Heading, Stack, Text, TextLink } from '@sparky';
import { ChevronRightIcon } from '@sparky/icons';
import { HeadingProps } from '@sparky/types';

const ContentCategories: FC<ContentCategoriesRendering> = ({ fields, params }) => {
  const Link = useLinkComponent();

  if (!fields?.items || fields.items.length === 0) {
    return null;
  }

  const headingLevel = params?.headingLevel ?? 'h2';

  //@ts-ignore these props always match
  const nestedTitleLevel: HeadingProps['as'] = `h${Math.min(6, +headingLevel.charAt(1) + 1)}`;

  const generateExpandable = (item: Item, key: number) => (
    <Grid.Item key={key}>
      <Box paddingX={{ initial: '0', md: '6' }}>
        <Expandable>
          {(item.fields.title?.value || item.fields.title?.editable) && (
            <Expandable.Trigger label={item.fields.title.value}>
              <Box paddingY={{ initial: '5', md: '6' }}>
                <Heading as={nestedTitleLevel} size="S" color="inherit">
                  {wrap(item.fields.title)}
                </Heading>
              </Box>
            </Expandable.Trigger>
          )}
          <Expandable.Content>
            <Box paddingBottom="8">
              <Stack gap="6">
                {item.fields.description && (
                  <Text>{wrap(item.fields.description, <RichText html={item.fields.description.value} />)}</Text>
                )}
                {item.fields.items && item.fields.items.length > 0 ? (
                  <Box paddingLeft="1" paddingTop="3">
                    <Stack gap="5" as="ul">
                      {item.fields.items?.map((item, i) => {
                        const link = item?.fields?.link;
                        if (link?.value?.href && link.value.text) {
                          return (
                            <Stack.Item key={i} as="li">
                              {(item.fields.link.value.href || item.fields.link.editable) && (
                                <Link
                                  editable={item.fields.link.editable}
                                  linkType={item.fields.link.value.linktype}
                                  linkValue={item.fields.link.value}>
                                  <TextLink emphasis="medium" leftIcon={<ChevronRightIcon />}>
                                    {item.fields.link.value.text}
                                  </TextLink>
                                </Link>
                              )}
                            </Stack.Item>
                          );
                        }
                      })}
                    </Stack>
                  </Box>
                ) : null}
              </Stack>
            </Box>
          </Expandable.Content>
        </Expandable>
      </Box>
      <Bleed horizontal={{ initial: 'gridGutter', md: '0' }}>
        <Divider />
      </Bleed>
    </Grid.Item>
  );

  const itemsPerColumn = Math.ceil(fields.items.length / 2);

  return (
    <>
      {(fields.title?.value || fields.title?.editable) && (
        <Box>
          <Heading as={headingLevel} size="M">
            {wrap(fields.title)}
          </Heading>
        </Box>
      )}
      <Grid columns={{ initial: '1', md: '2' }} columnGap="6">
        <Stack>{fields.items.slice(0, itemsPerColumn).map((item, i) => generateExpandable(item, i))}</Stack>
        <Stack>{fields.items.slice(itemsPerColumn).map((item, i) => generateExpandable(item, i))}</Stack>
      </Grid>
    </>
  );
};

export default ContentCategories;
